import React from 'react'

const Testimonial = ({ clientMessage, clientImage, clientName }) => {
  return (
    <div className=" testimonial-item-container">
      <div className="row justify-content-center testimonial-row">
        <div className="col-12 col-sm-8 align-self-center">
          <div className="d-flex flex-column align-items-center">
            <div className="text-center">{clientMessage}</div>
            <img
              className="testimonial-image my-2"
              src={clientImage}
              alt="customer hair short blonde"
            />
            <div>{clientName}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Testimonial
